<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo hidden">
        <h2 class="brand-text text-primary ml-1">
          Phire Studio
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <img :src="daikinLogo" style="width: 100%; height: auto; margin: 0 0 15px 0"/>

          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            RSP Portal
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account to login
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <!-- <b-button
                type="submit"
                variant="primary"
                block
                @click="loginUser"
              > -->
              
              <!-- Unblock this if  using otp -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="loginOtp"
              >
             

                Sign in
              </b-button>
              <!--
              <b-button
                type="submit"
                variant="primary"
                block
                :to="{ name: 'login-qr'}"
              >
             

                Login With Barcode
              </b-button>
              -->
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link @click="registerUser">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <div class="divider my-2">
            <!-- <div class="divider-text">
              or
            </div> -->
          </div>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { mapState } from 'vuex';
// import { viewAccessLogin } from '@/utils/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      daikinLogo: require('@/assets/images/drcc/daikin-logo.png')
    }
  },
  computed: {
    // ...mapState({
    //   prevAuthPage: (state) => state.auth.prevAuthPage
    // }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    loginOtp(){ // disini data email dan password akan dimasukkan ke dalam store
      this.$store.dispatch('auth/loginOtp', {
        email : this.userEmail,
        password: this.password,
      }).then((res) => {
        this.$bvToast.toast(`OTP has been sent to ${this.userEmail}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        setTimeout(() => {
          this.$router.push('/otp');
        },3000)
      }).catch(err => {
        if (err.response.data.errors) {
            this.$bvToast.toast(err.response.data.errors[0].message, {
            title: 'Failed',
            variant: 'danger',
            solid: true,
            })
          } else{
            this.$bvToast.toast('Invalid Credentials', {
              title: 'Failed',
              variant: 'danger',
              solid: true,
            })
          }
          // console.log(err)
          console.log(err)
        })
    },
    loginUser() {
      this.$store
        .dispatch('auth/login', {
          email: this.userEmail,
          password: this.password,
        })
        .then((userData) => {
          
          // if (this.prevAuthPage !== undefined) {
          //   let prevPage = this.prevAuthPage;
          //   let isAdminRoute = prevPage.match(/\/admin\/*/)
          //   let isDealerRoute = prevPage.match(/\/dealer\/*/)
          //   let isInstallerRoute = prevPage.match(/\/installer\/*/)
          //   let customerRoute = prevPage.match(/\/customer\/*/)

          //   let  role  = this.$session.get('currentRole');
          //   // console.log({userData})
          //   // console.log('role',role)
          //   this.$store.commit('auth/SET_PREV_AUTH_PAGE', '/');

          //   console.log(prevPage)

          //   if (role === 'Dealer') {
          //     this.$router.replace('/dealer/orders');
          //   } else if (role === 'Installer' && isInstallerRoute) {
          //     this.$router.replace(prevPage);
          //   } else if (role === 'Installer') {
          //     this.$router.replace('/installer/work-orders')
          //   } else if (role === 'Warranty') {
          //     this.$router.replace('/warranty/paperless')
          //   } else if (role === 'Customer') {
          //     this.$router.replace('/warranty/paperless/list-individual')
          //   } else {
          //     this.$router.replace('/admin/dealer');
          //   }
          // } else {
          //   // let { role } = userData;
          //   let  role  = this.$session.get('currentRole');

          //   if (role === 'Dealer') {
          //     this.$router.replace('/dealer/orders');
          //   } else if (role === 'Installer') {
          //     this.$router.replace('/installer/work-orders')
          //   } else {
          //     this.$router.push('/')
          //   }
          // }
          var permission = JSON.parse(this.$session.get('permission'));
          console.log('loginDataPermission',permission)

          let userAccess = permission[Object.keys(permission)[0]].filter(el => {
              return el.view;
            }).map(el => {
              return `${el.name.replace(/\s+/g, '-').toLowerCase()}`;
            })
          console.log('useraccessss',userAccess)
          console.log('useraccessss[0]',userAccess[0])
          this.$router.push('/');
          /**
          if (userAccess.length > 0) {
            this.$router.push({ name: `${userAccess[0]}` });
          } else {
            this.$router.push('/');
          }
          */
          // this.$router.push('/item');

        })
        .catch(err => {
          this.$bvToast.toast('Invalid Credentials', {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          })
          // console.log(err)
          console.log(err)
        })
    },
    registerUser() {
      this.$router.push('/pages/register').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
